import React, { MouseEventHandler, useEffect, useState } from 'react';
import { ArrowDown, PlusCircle } from '../../../svgs';
import { useAppDispatch, useAppSelector, useUser } from '../../../state';
import { SearchFieldContainer } from '../../../containers/core/search-field-container';
import { Footer } from '../footer';
import { MenuBurgerButton } from '../../../svgs/MenuBurgerButton';
import { closeModal, openModal } from '../../../state/actions/modalsActions';
import { CommunityMetaData } from '../../../routes/community/community-meta-data';
import { Flex } from '../../../atoms';
import { Detail } from '../../../routes/paper';
import {
  selectCommunityData,
  selectCommunityGetLoading
} from '../../../state/selectors/communitySelector';
import { Loading } from '../loading';
import { formatDate } from '../../../utils/userPostHelper';
import { AuthButtons } from './auth';
import { TopBarModals } from '../topBarModals';
import { NewsTicker } from '../../newsTicker';
import { MainLogo } from '../mainLogo';
import { PAPERS_DISCUSSIONS_RADIO_STATES } from '../../../routes/constants';
import { PapersCommunityFilter } from '../../filter/papersCommunityFilter';

export interface Props {
  children: React.ReactNode;
}

export const CommunityTopBar: React.FC<Props> = (props) => {
  const communityData = useAppSelector(selectCommunityData);
  const loading = useAppSelector(selectCommunityGetLoading);

  const user = useUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const papersDiscussionsRadioState = useAppSelector(
    (state) => state.tabs.papersDiscussionsRadioState
  );
  function handleLogoutClick() {
    dispatch({ type: 'LOGIN_SET_USER', user: null });
    localStorage.removeItem('auth');
  }

  function handleToggleDropdown() {
    setShowDropdown((v) => !v);
  }

  function handleHideDropdown() {
    setShowDropdown(false);
  }

  const handleCloseModal = (modalName: string) => {
    dispatch(closeModal(modalName));
  };

  const handleOpenModal = (modalName: string) => {
    dispatch(openModal(modalName));
  };
  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  const [isMenuVisible, setisMenuVisible] = useState(false);

  const toggleVisibility = () => {
    setisMenuVisible(!isMenuVisible);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      target.closest('.hamburger-menu') ||
      target.closest('.menu-button button') ||
      target.closest('.modal-overlay')
    ) {
      return;
    }
    setisMenuVisible(false);
  };

  useEffect(() => {
    if (isMenuVisible) {
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [isMenuVisible]);

  if (loading) return <Loading className="imp-static" />;

  const { children } = props;
  return (
    <div className="tablet:flex-no-wrap  flex-no-wrap w-full flex sticky top-0 z-50">
      <div
        className={`tablet:flex-no-wrap h-auto flex-col mr-0 top-bar-left flex justify-start h-full hamburger-menu ${
          isMenuVisible ? 'showMenu' : ''
        }`}
      >
        <div className="top-bar-border-bottom pt-4 pb-4 px-4">
          <MainLogo />
          <div className="relative z-10 flex  flex-grow ">
            {user ? (
              <div className="relative w-full pt-4">
                <button
                  className="ml-auto w-full flex justify-between items-center"
                  onClick={handleToggleDropdown}
                  onBlur={handleHideDropdown}
                >
                  <span className="mr-1 userName">{user.displayName}</span>
                  <span className="">
                    <ArrowDown className="opacity-50" />
                  </span>
                </button>
                {showDropdown && (
                  <div
                    className="top-full absolute right-0 mt-2 text-sm font-medium"
                    onMouseDown={handleMouseDown}
                  >
                    <div
                      className="hover:text-primary-500 px-4 py-2 transition-colors 
                      duration-200 bg-white shadow-md cursor-pointer"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <AuthButtons />
            )}
          </div>
        </div>
        <div className="h-full flex flex-col justify-between">
          <div className="px-4 pt-4 ">
            {user ? (
              <div className="">
                <Flex direction="flex-row" className="mb-2  mr-3">
                  <button
                    onClick={() => handleOpenModal('createPost')}
                    className="btnSmall text-white bg-gradient-to-r text-small 
                    from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded 
                    opacity-100 hover:opacity-50 font-medium focus:outline-none w-full text-center"
                  >
                    <div className="flex items-center justify-center">
                      <PlusCircle className=" mr-1" />
                      <span className="font-medium uppercase">
                        Create a new post
                      </span>
                    </div>
                  </button>
                </Flex>
                <Flex direction="flex-row" className="mb-4  mr-3">
                  <button
                    onClick={() => handleOpenModal('createCommunity')}
                    className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight 
                    to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 
                    font-medium focus:outline-none w-full text-center"
                  >
                    <div className="flex items-center justify-center">
                      <PlusCircle className=" mr-1" />
                      <span className="font-medium uppercase">
                        Create a Community
                      </span>
                    </div>
                  </button>
                </Flex>
              </div>
            ) : (
              ''
            )}
            {communityData?.createdByUser?.displayName && (
              <Detail heading="Community Creator" className="pt-4">
                {communityData.createdByUser?.displayName}
              </Detail>
            )}
            {communityData?.createdUtc && (
              <Detail heading="Creation Date" className="">
                {formatDate(communityData?.createdUtc)}
              </Detail>
            )}
            {communityData?.description && (
              <div className="whitespace-pre-line">
                <Detail heading="About" className="whitespace-pre-line">
                  {communityData.description}
                </Detail>
              </div>
            )}
            {communityData?.topicTags && (
              <CommunityMetaData topics={communityData.topicTags} />
            )}
            {papersDiscussionsRadioState ===
              PAPERS_DISCUSSIONS_RADIO_STATES.PAPERS && (
              <PapersCommunityFilter />
            )}
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </div>
      <div className="w-full relative min-h-screen bg-silv main-page h-full">
        <div>
          <NewsTicker />
        </div>
        <div className="flex justify-center pt-3 pb-3 tablet:px-4 px-2 bg-white">
          <SearchFieldContainer />
          <div className="menu-button">
            <button onClick={toggleVisibility}>
              <MenuBurgerButton />
            </button>
          </div>
        </div>
        <div
          className="tablet:mt-0 tabsContainer tablet:order-none 
        relative z-10 flex justify-center flex-grow order-last w-full "
        >
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
      <TopBarModals closeModal={handleCloseModal} />
    </div>
  );
};
