/* eslint-disable max-len */
export const texts = {
  view: 'View',
  oneHub: 'One hub for research',
  topics: 'Topics',
  topicTags: 'Topics',
  communityTags: 'Communities',
  communities: 'Communities',
  createCommunity: 'Create a Community',
  createNewCommunity: 'Create a new Community',
  createCommunityButton: 'Create Community',
  editCommunity: 'Edit Community',
  editCommunityText:
    'If you require further assistance kindly contact us at <a href="mailto:support@oipub.com">support@oipub.com</a>',
  createPost: 'Create a Post',
  editPost: 'Edit Post',
  communityName: 'Community Name',
  updateCommunityButton: 'SAVE Community',
  papers: 'Papers',
  posts: 'Posts',
  postName: 'Post Name',
  createPostButton: 'Create Post',
  createNewPostButton: 'Create a new post',
  updatePostButton: 'Save Post',
  text: 'Text',
  discussions: 'Discussions',
  description: 'Description',
  activity: 'Activity',
  feed: 'Feed',
  title: 'Title',
  login: 'Login',
  search: 'Search',
  deleted: 'deleted',
  filter: 'Filters',
  filterTopic: 'Topic Name',
  authors: 'Authors',
  comments: 'Comments',
  citations: 'Citations',
  published: 'Published',
  posted: 'Posted',
  chronological: 'Chronological',
  returnToResult: 'Return to results',
  sortedBy: 'Sorted by',
  alphabetically: 'Alphabetically',
  discussionActivity: 'Discussion Activity',
  topicsDescription: `Topics are system-created information hubs where you can find and discuss 
  all information related to one topic. For now this includes research papers and discussion posts 
  but we plan to expand our library with other information categories such as datasets and authors in future. 
  Topics are also used to broadcast information to Communities.`,
  communitiesDescription: `Communities are user-created information hubs where you can find and discuss 
  all information related to one topic. For now this includes research papers and discussion posts 
  but we plan to expand our library with other information categories such as datasets and authors in future.`,
  createCommunityDescription: `Communities are user-created hubs which can follow and discuss multiple Topics. 
  Communities are populated with information from all the Topics that they follow. For now all Communities are public, 
  in future we plan to also offer private communities for research teams.  
  Find a community below to read a paper or ask a question related to that Community. 
  Create a new Community for you and your colleagues if you can’t find an existing community that follows Topics you are interested in.`,
  communityNameDescription: `This must be unique and cannot be the same as an existing Topic name. e.g. 
  “European Anaesthesia Community” rather than “Anaesthesia”.`,
  descriptionExplain:
    'Provide a short description of what this community is about.',
  topicTagsExplain: `Select which Topic Tags you wish to follow in this community. 
  The community will be automatically populated with content (e.g. papers, posts) linked to this tag.`,
  createNewPostDescription: `Posts are used to start a discussion or ask a question. Posts can be broadcast 
  by Topic Tag so that all Communities as well as the Topics themselves can see the post. If you wish to reach 
  a specific community without Topic-based broadcasting, you can also post directly to one or more Communities.`,
  postPagetopicTagsExplain: `Select any Topic Tags you wish to broadcast to. Your post will be broadcast to these 
  Topics and all Communities that follow these Topic Tags.`,
  postPageCommunityTagsExplain: `If you want to post directly to a specific community, without 
  broadcasting to multiple communities following a relevant Topic Tag, you can select the Community you wish to post to here.`,
  searchFieldPlaceHolder: 'Search our database',
  signup: 'Sign up',
  checkbox:
    'I am aware and accept that moderation of Public communities may in future move to a technocratic or community managed system, resulting in changes in moderation rights.',
  disabledCommunity:
    'Community update in progress. Please check back in a few minutes.',
  limitTopics:
    'Topic limit reached. Talk to us at <a href="mailto:info@oipub.com">info@oipub.com</a> if you believe this should be expanded.',
  limitCommunity:
    'Community limit reached. Talk to us at <a href="mailto:info@oipub.com">info@oipub.com</a> if you believe this should be expanded.',
  newsTickerContent:
    '<a href="https://oipub.com/post/48">Database Update in Progress: Papers, Topics, Scores. Want your topics updated now? Click here for details!</a>',
  papersWithoutAbstract:
    '<b>No abstract is available.</b><br/>Check the paper links on the left panel for further details on this publication.<br/><br/>Ask a question or share your thoughts on this publication in the comments below.<br/><br/>Some publications legitimately do not have an abstract. Most though are simply not shared by publishers.<br/><br/><b>You can help change this!</b><br/>Promote unrestricted access to scholarly abstracts.<br/><br/><ul><li>Support the Initiative for Open Abstracts (I4OA, <a href="https://i4oa.org/" target="_blank">https://i4oa.org/</a>).</li><li>Petition publishers to share all abstracts from their catalog.</li><li>Avoid using publishers who don’t support the I4OA and open science.</li></ul><br/><i>Tip: the I4OA website has a helpful diagram showing the largest publishers who don’t share!</i>'
};
