import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaperFilterData } from '../../types/filter.type';
import { IPaperPaged } from '../../types/papers.type';
import { env } from '../../env';

export const fetchTopicPapersData = createAsyncThunk<
  IPaperPaged,
  { filterData: IPaperFilterData; topicUrlKey: string }
>(
  'get/fetchTopicPapersData',
  async ({ filterData, topicUrlKey }, { rejectWithValue }) => {
    try {
      const query =
        `pageNum=${filterData.pageNum?.toString() || '1'}` +
        `&pageSize=${filterData.pageSize?.toString() || '10'}` +
        (filterData.title ? `&title=${filterData.title}` : '') +
        (filterData.author ? `&author=${filterData.author}` : '') +
        (filterData.journal ? `&journal=${filterData.journal}` : '') +
        (filterData.dateFrom ? `&dateFrom=${filterData.dateFrom}` : '') +
        (filterData.dateTo ? `&dateTo=${filterData.dateTo}` : '');

      const url = `${env.apiUrl}/api/v1/topics/${topicUrlKey}/papers/filter?${query}`;
      const response = await fetch(url, {
        method: 'GET'
      });

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('No papers found for the applied filters');
        }
        throw new Error(response.statusText);
      }
      const data: IPaperPaged = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Failed to fetch topic papers data'
      );
    }
  }
);

export const fetchCommunityPapersData = createAsyncThunk<
  IPaperPaged,
  { filterData: IPaperFilterData; communityUrlKey: string }
>(
  'get/fetchCommunityPapersData',
  async ({ filterData, communityUrlKey }, { rejectWithValue }) => {
    try {
      const query =
        `pageNum=${filterData.pageNum?.toString() || '1'}` +
        `&pageSize=${filterData.pageSize?.toString() || '10'}` +
        (filterData.title ? `&title=${filterData.title}` : '') +
        (filterData.author ? `&author=${filterData.author}` : '') +
        (filterData.journal ? `&journal=${filterData.journal}` : '') +
        (filterData.dateFrom ? `&dateFrom=${filterData.dateFrom}` : '') +
        (filterData.dateTo ? `&dateTo=${filterData.dateTo}` : '');

      const url = `${env.apiUrl}/api/v1/communities/${communityUrlKey}/papers/filter?${query}`;

      const response = await fetch(url, {
        method: 'GET'
      });

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('No papers found for the applied filters');
        }
        throw new Error(response.statusText);
      }

      const data: IPaperPaged = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Failed to fetch community papers data'
      );
    }
  }
);
