import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import {
  setCommunityPaperFilterData,
  setTopicPaperFilterData
} from '../../../state/slices/filterPapersReducer';
import {
  fetchCommunityPapersData,
  fetchTopicPapersData,
  useAppDispatch
} from '../../../state';

export const useFilterPagination = (
  isCommunity: boolean,
  urlKey: string | undefined
) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const updatePageForFilter = (newPage: number) => {
    const updatedParams = {
      ...queryParams,
      pageNum: newPage.toString()
    };
    navigate({
      pathname: location.pathname,
      search: `?${new URLSearchParams(updatedParams).toString()}`
    });

    const filterData = {
      title: queryParams.title ? queryParams.title.toString() : null,
      author: queryParams.author ? queryParams.author.toString() : null,
      journal: queryParams.journal ? queryParams.journal.toString() : null,
      dateFrom: queryParams.dateFrom ? queryParams.dateFrom.toString() : null,
      dateTo: queryParams.dateTo ? queryParams.dateTo.toString() : null,
      pageNum: newPage,
      pageSize: queryParams.pageSize
        ? parseInt(queryParams.pageSize as string, 10)
        : 10
    };

    if (isCommunity) {
      dispatch(setCommunityPaperFilterData(filterData));
      dispatch(
        fetchCommunityPapersData({ filterData, communityUrlKey: urlKey! })
      );
    } else {
      dispatch(setTopicPaperFilterData(filterData));
      dispatch(fetchTopicPapersData({ filterData, topicUrlKey: urlKey! }));
    }
  };

  return { updatePageForFilter };
};
