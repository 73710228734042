import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Span } from '../../atoms';

interface PapersMainFilterProps {
  urlKey: string;
  setFilterData: (filterData: any) => void;
  fetchData: (filterData: any) => void;
  resetFilterData: () => void;
}

const PaperFilterSchema = Yup.object().shape({
  title: Yup.string().nullable(),
  author: Yup.string().nullable(),
  journal: Yup.string().nullable(),
  dateFrom: Yup.date().nullable(),
  dateTo: Yup.date().nullable()
});

export const PapersMainFilter: React.FC<PapersMainFilterProps> = ({
  urlKey,
  setFilterData,
  fetchData,
  resetFilterData
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const urlInitialValues = {
    title: searchParams.get('title') || '',
    dateFrom: searchParams.get('dateFrom') || '',
    dateTo: searchParams.get('dateTo') || '',
    author: searchParams.get('author') || '',
    journal: searchParams.get('journal') || ''
  };

  // Update URL query string with filters and pagination
  const updateURLWithFilters = (
    values: any,
    pageNum: number,
    pageSize: number
  ) => {
    // Filter out empty values using Object.fromEntries and Array.filter
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([, value]) => value) // Keep only non-empty values
    );

    const params = new URLSearchParams({
      ...filteredValues,
      pageNum: pageNum.toString(),
      pageSize: pageSize.toString()
    });

    setSearchParams(params);
  };

  const handleApply = (values: any, pageNum = 1, pageSize = 10) => {
    updateURLWithFilters(values, pageNum, pageSize);
    const filterWithPagination = { ...values, pageNum, pageSize };
    setFilterData(filterWithPagination);
    fetchData({
      filterData: filterWithPagination,
      urlKey
    });
  };

  const handleReset = (
    resetForm: () => void,
    setFieldValue: (field: string, value: any) => void
  ) => {
    resetForm();
    setFieldValue('dateFrom', '');
    setFieldValue('dateTo', '');

    const resetValues = {
      title: '',
      dateFrom: '',
      dateTo: '',
      author: '',
      journal: ''
    };

    // Update the URL query string without pagination parameters
    const filteredValues = Object.fromEntries(
      Object.entries(resetValues).filter(([, value]) => value)
    );

    const params = new URLSearchParams(filteredValues);
    setSearchParams(params);
    resetFilterData();
  };

  const handleSubmit = (values: typeof urlInitialValues) => {
    handleApply(values);
  };

  // On component mount, update form values if present in the URL
  useEffect(() => {
    const pageNum = searchParams.get('pageNum')
      ? parseInt(searchParams.get('pageNum')!)
      : 1;
    const pageSize = searchParams.get('pageSize')
      ? parseInt(searchParams.get('pageSize')!)
      : 10;

    const filterWithPagination = { ...urlInitialValues, pageNum, pageSize };

    if (
      urlInitialValues.title ||
      urlInitialValues.dateFrom ||
      urlInitialValues.dateTo ||
      urlInitialValues.author ||
      urlInitialValues.journal
    ) {
      setFilterData(filterWithPagination);
      fetchData({
        filterData: filterWithPagination,
        urlKey
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run only at start

  return (
    <div className="filter-container">
      <Span
        color="black"
        size="sm"
        className="block mb-4 uppercase font-medium"
      >
        Filter
      </Span>
      <Formik
        initialValues={urlInitialValues}
        validationSchema={PaperFilterSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({ values, resetForm, setFieldValue }) => (
          <Form className="w-full">
            <div className="flex flex-col filters">
              <Span color="black" size="sm" className="mr-4 font-medium">
                Title
              </Span>
              <Field
                id="title"
                name="title"
                type="text"
                placeholder="Title"
                className="tw-px-4 tw-py-4 text-xs focus:tw-outline-none h-8 border w-full px-3 mb-4"
                value={values.title}
              />
              <div className="flex items-center mb-4 justify-between">
                <Span color="black" size="sm" className="mr-4 font-medium">
                  From
                </Span>
                <div className="min-max flex">
                  <Field
                    id="dateFrom"
                    name="dateFrom"
                    type="date"
                    className="tw-px-4 tw-py-4 text-xs focus:tw-outline-none h-8 border w-full px-3"
                    value={values.dateFrom}
                  />
                </div>
              </div>
              <div className="flex items-center mb-4 justify-between">
                <Span color="black" size="sm" className="mr-4 font-medium">
                  To
                </Span>
                <div className="min-max flex">
                  <Field
                    id="dateTo"
                    name="dateTo"
                    type="date"
                    className="tw-px-4 tw-py-4 text-xs focus:tw-outline-none h-8 border w-full px-3"
                    value={values.dateTo}
                  />
                </div>
              </div>
              <Span color="black" size="sm" className="font-medium">
                Author
              </Span>
              <Field
                id="author"
                name="author"
                type="text"
                placeholder="Author"
                className="tw-px-4 tw-py-4 text-xs focus:tw-outline-none h-8 border w-full px-3 mb-4"
                value={values.author}
              />
              <Span color="black" size="sm" className="font-medium">
                Journal
              </Span>
              <Field
                id="journal"
                name="journal"
                type="text"
                placeholder="Journal"
                className="tw-px-4 tw-py-4 text-xs focus:tw-outline-none h-8 border w-full px-3 mb-4"
                value={values.journal}
              />
              <div className="flex mb-1">
                <button
                  type="submit"
                  className="btnSmall text-white bg-gradient-to-r from-primaryLight to-primaryDefault px-4 py-2 gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none mr-2 w-full text-center"
                  disabled={
                    !values.title &&
                    !values.dateFrom &&
                    !values.dateTo &&
                    !values.author &&
                    !values.journal
                  }
                >
                  Apply
                </button>
                <button
                  type="button"
                  onClick={() => handleReset(resetForm, setFieldValue)}
                  className="btnSmall text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-4 py-2 text-sm font-medium transition-colors duration-200 bg-white border border-current rounded w-full text-center"
                  disabled={
                    !values.title &&
                    !values.dateFrom &&
                    !values.dateTo &&
                    !values.author &&
                    !values.journal
                  }
                >
                  Reset
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
